import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Anafit from "../sliki.png/anafit.png";
import { useTranslation } from "react-i18next";

export default function SimpleSlider() {
  const { t } = useTranslation();
  return (
    <Carousel showThumbs={false}>
      <div>
        <img
          style={{
            width: "200px",
          }}
          src={Anafit}
          alt="slika"
        />
        <p className="legend">{t("slide.1")}</p>
      </div>
      <div>
        <img
          style={{
            width: "200px",
          }}
          src={Anafit}
          alt="slika"
        />
        <p className="legend"> {t("slide.2")} </p>
      </div>
      <div>
        <img
          style={{
            width: "200px",
          }}
          src={Anafit}
          alt="slika"
        />
        <p className="legend">{t("slide.3")}</p>
      </div>
    </Carousel>
  );
}
