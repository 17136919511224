import { BrowserRouter as Router } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Reklama from "./Reklama.js";
import Registriraj from "./Registriraj";
import SimpleSlider from "../Home/Slide.js";
import jasjas4 from "../sliki.png/ana4.png";
import ana from "../ana.png";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import FormRow from "../Home/Row.js";
import Cards from "./Cards.js";
export default function Glavno() {
  const { t } = useTranslation();
  React.useEffect(() => {}, []);
  return (
    <div style={{ marginTop: "60px", fontSizeAdjust: 10 }}>
      <Helmet>
        <title>{t("aboutUs.5")}</title>
        <meta property="og:title" content={t("aboutUs.5")} />
        <meta name="description" content={t("Thanks.1")} />

        <meta name="og:image" content={"/static/media/ana.a52c323c.png"} />
        <meta property="og:type" content="website"></meta>
        <meta itemprop="name" content={t("aboutUs.5")}></meta>
        <meta itemprop="description" content={t("Thanks.1")}></meta>
        <meta itemprop="image" content="/static/media/ana.a52c323c.png"></meta>
      </Helmet>
      <React.Fragment
        style={{
          margin: "10px",
          display: "inlineBlock",
        }}
      >
        <Grid
          container
          style={{
            display: "inlineBlock",
            alignItems: " right",
            marginTop: "60px",
          }}
        >
          {" "}
          <Grid itam xs={12} md={6}>
            <img
              src={jasjas4}
              alt="slika"
              style={{
                maxWidth: "100%",
                maxHeight: "100% ",
              }}
            />
          </Grid>
          <Grid itam xs={12} md={6}>
            <img
              src={ana}
              alt={ana}
              style={{ width: "200px", paddingTop: "40px" }}
            />{" "}
            <p style={{ fontSize: "30px", fontStyle: "oblique" }}>
              {t("Thanks.1")}
            </p>
          </Grid>
        </Grid>
      </React.Fragment>
      <Grid>
        <Registriraj />
      </Grid>
      <NestedGrid />
      <Reklama />
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "purple",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function NestedGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={3}>
          <FormRow />
        </Grid>
      </Grid>
      <Cards />

      <div style={{ padding: "20px", paddingBottom: "50px" }}>
        <SimpleSlider />
      </div>
    </div>
  );
}
