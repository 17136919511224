import { FaDumbbell } from "react-icons/fa";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaRunning } from "react-icons/fa";
import { MdPregnantWoman } from "react-icons/md";
import { FaAppleAlt } from "react-icons/fa";
import meditacija from "../sliki.png/ana3.png";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import React from "react";
export default function FormRow() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Paper
            style={{
              marginTop: " 20px",
              textColor: "purple",
            }}
          >
            <FaDumbbell size="30px" color="purple" />
            <h3>{t("Thanks.2")}</h3>
            <p>{t("Thanks.3")}</p>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            style={{
              textColor: "purple",
              margin: "1px",
              paddingBottom: 0,
              marginBottom: 0,
            }}
          >
            <FaYoutubeSquare size="30px" color="purple" />
            <h3>{t("Thanks.4")}</h3>
            <p>{t("Thanks.5")}</p>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            style={{
              marginTop: " 20px",
              textColor: "purple",
              marginLeft: "1px",
            }}
          >
            <FaAppleAlt size="30px" color="purple" /> <h3>{t("Thanks.6")}</h3>
            <p>{t("Thanks.7")}</p>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            style={{
              marginTop: " 60px",
              textColor: "purple",
            }}
          >
            <FaRunning size="30px" color="purple" /> <h3>{t("Thanks.8")}</h3>
            <p>{t("Thanks.9")}</p>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <img
            style={{
              blockSize: "400px",
              margin: "0px",
              border: "none",
              paddingLeft: "60px",
              paddingTop: 0,
              marginTop: 0,

              maxWidth: "100%",
              maxHeight: "100% ",
            }}
            src={meditacija}
            alt="meditacija"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper
            style={{
              marginTop: " 10px",
              textColor: "purple",
              marginLeft: "80px",
            }}
          >
            <MdPregnantWoman size="40px" color="purple" />{" "}
            <h3>{t("Thanks.10")}</h3>
            {t("Thanks.11")}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
