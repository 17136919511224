import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
const Button = styled.button`
  background-color: purple;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
`;
function Registriraj() {
  const { t } = useTranslation();
  return (
    <div style={{ marginBottom: "80px" }}>
      <h1
        style={{
          marginBottom: "50px",
        }}
      >
        {t("start.1")}
      </h1>
      <Grid>
        <a
          href="https://www.patreon.com/anafit1?fan_landing=true"
          target="_blank"
        >
          <Button
            style={{ height: "70px", maxWidth: "100%", maxHeight: "100% " }}
          >
            {t("start.2")}{" "}
          </Button>
        </a>
      </Grid>
    </div>
  );
}

export default Registriraj;
