import React from "react";
import ReactPlayer from "react-player";

export default function Reklama() {
  return (
    <div>
      <ReactPlayer
        style={{ maxWidth: "100%", maxHeight: "100% ", display: "inline-flex" }}
        playing={true}
        muted={true}
        loop={true}
        url="https://www.youtube.com/watch?v=jQYDFiBj23A"
      />
    </div>
  );
}
